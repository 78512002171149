export const categorizationEnumValues = {
  career: 'Career',
  careerPrep: 'Career Prep',
  advice: 'Advice',
  lifestyle: 'Lifestyle',
  leadership: 'Leadership',
  education: 'Education',
  highSchool: 'High School',
  college: 'College',
  tips: 'Tips',
  downloads: 'Downloads',
  internship: 'Internship',
  resume: 'Resume',
  scholarship: 'Scholarship',
  quiz: 'Quiz',
  podcast: 'Podcast',
  profileSeries: 'Profile Series',
  miniWall1: 'Mini Wall 1',
  miniWall2: 'Mini Wall 2',
  miniWall3: 'Mini Wall 3',
  miniWall4: 'Mini Wall 4',
  miniWall5: 'Mini Wall 5',
  miniWall6: 'Mini Wall 6',
};

export const colorEnumValues = {
  blue: {
    id: 'blue',
    label: 'Blue',
    color: 'blueDarker',
  },
  purple: {
    id: 'purple',
    label: 'Purple',
    color: 'purpleLight',
  },
  green: {
    id: 'green',
    label: 'Green',
    color: 'greenLight',
  },
  orange: {
    id: 'orange',
    label: 'Orange',
    color: 'orangeLight',
  },
  cyan: {
    id: 'cyan',
    label: 'Cyan',
    color: 'cyanLight',
  },
} as any;
